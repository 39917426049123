<script>
    import VisibleDates from '$src/views/Tour/VisibleDates.svelte';
    export let tourDatesInfo, visibleDates, dict, isTravelAloneAuthorized;
</script>

<div class="desktop:hidden">
    <VisibleDates
        {tourDatesInfo}
        {visibleDates}
        {dict}
        {isTravelAloneAuthorized}
    />
</div>
