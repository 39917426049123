<script lang="ts">
    import type { HTMLButtonAttributes } from 'svelte/elements';
    import type { VariantProps } from 'class-variance-authority';
    import { cva } from 'class-variance-authority';

    import cn from '$src/utils/cn';

    // TODO migrate to vtmn-play Svelte library
    const button = cva('vp-button', {
        variants: {
            intent: {
                primary: 'vp-button--primary',
                secondary: 'vp-button--secondary',
                tertiary: 'vp-button--tertiary',
                negative: 'vp-button--negative',
                inverse: 'vp-button--inverse',
                disabled: 'opacity-30',
                link: 'bg-none bg-transparent text-content-neutral underline',
            },
            shape: {
                squared: 'vp-button--squared',
                rounded: 'vp-button--rounded',
            },
            size: {
                none: 'p-0',
                small: 'vp-button--small',
                medium: 'vp-button--medium',
                large: 'vp-button--large',
            },
        },
        compoundVariants: [],
    });

    /**
     * For Svelte components, we recommend setting your defaultVariants within
     * Svelte props (which are `undefined` by default)
     */
    interface $$Props
        extends HTMLButtonAttributes,
            VariantProps<typeof button> {}

    /**
     * For Svelte components, we recommend setting your defaultVariants within
     * Svelte props (which are `undefined` by default)
     */
    export let intent: $$Props['intent'] = 'primary';
    export let size: $$Props['size'] = 'medium';
    export let shape: $$Props['shape'] = 'squared';
    export let disabled: $$Props['disabled'] = false;
</script>

<button
    {...$$props}
    on:click
    {disabled}
    class={cn(button({ intent, size, shape }), $$props.class)}
>
    <slot />
</button>
